import { stats, StatsOptions } from '@fiverr-private/obs';
import { STATS_PREFIX, DATA_SIZE } from './constants';

const prefixed = stats.prefix(STATS_PREFIX);

export const time = prefixed.time;
export const count = prefixed.count;

/**
 * Size monitoring that calls time
 * @param metric - The metric name
 * @param data - The data to measure
 * @param options - Sample rate represented by decimal point fraction [0-1]
 */
export const size = (
    metric: string,
    data: unknown,
    options: StatsOptions = {}
) => {
    const dataString = (typeof data === 'string') ? data : JSON.stringify(data);

    prefixed.time(`${DATA_SIZE}.${metric}`, dataString.length, options);
};
