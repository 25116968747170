import { logger } from '@fiverr-private/obs';
import { count } from '@fiverr-private/layout-lib/stats';
import { addError } from '@fiverr-private/monicore';

/**
 * Handles CSR errors.
 * @param error The error to handle.
 * @param componentName The component's name.
 * @param entryName The component's entry name.
 */
export const handleError = (error: unknown, componentName: string, entryName: string) => {
    const description = `Layout component: ${componentName}, failed on entry: ${entryName}.`;
    const errorName = `layout.CSR.${componentName}.${entryName}`;

    logger.error(
        error instanceof Error ? error : new Error(description),
        {
            description,
            componentName,
            entryName,
            package: '@fiverr-private/layout-sdk'
        }
    );
    addError(errorName);
    count(`layout_component.${componentName}.error`);
};
