import React from 'react';
import { SerializedOutput } from '@fiverr-private/layout-component';
import CategoriesMenu from '@fiverr-private/categories_menu/src';

/**
 * Categories menu wrapper to support old layout css classes. TODO: move to the categories_menu package
 */
const CategoriesMenuWrapper: React.FC<SerializedOutput> = (props: SerializedOutput) => (
    <div className="categories-menu-wrapper categories_menu-package">
        <CategoriesMenu {...props}/>
    </div>
);

export default CategoriesMenuWrapper;
