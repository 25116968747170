import React, { ComponentType } from 'react';
import ReactDOM from 'react-dom';

import { startSpan, endSpan } from '@fiverr-private/monicore';

import { ErrorBoundary } from './ErrorBoundary';
import { handleError } from './handleError';

/**
 * Hydrate CSR React component.
 * @param Component The React component to hydrate.
 * @param props The component's props object.
 * @param container The container element to render the component into.
 * @param componentName The component's name.
 * @param entryName The component's entry name.
 */
export const hydrateCSRTemplate = (
    Component: ComponentType,
    props: Record<string, any>,
    container: ReactDOM.Container,
    componentName: string,
    entryName: string
) => {
    try {
        const spanName = `layout.CSR.${componentName}.${entryName}`;
        startSpan(spanName);
        ReactDOM.hydrate(
            <ErrorBoundary componentName={componentName}
                entryName={entryName}>
                <Component {...props}/>
            </ErrorBoundary>,
            container,
            () => {
                endSpan(spanName);
            }
        );
    } catch (error) {
        // we try catch it for the chance that something will be break during ReactDOM.hydrate (no doc)
        handleError(error, componentName, entryName);
    }
};
